<template>
  <!-- 开发者：王雪茹 -->
  <div class="hopper">
    <!-- 标题栏 -->
    <div class="title">
      <!-- 图标信息 -->
      <div>
        <svg class="icon inline_block" aria-hidden="true">
          <use href="#icon-upgrade"></use>
        </svg>
      </div>
      <!-- 文字标题 -->
      <div class="textTitle">
        <div>
          <!-- 销售阶段推进 -->
          {{ $t("vue_label_salesadvance") }}
        </div>
        <div style="font-size: 12px">
          <!-- 币种类型 -->
          {{ $t("label.currency.type") }}
          :{{ currency }}
        </div>
      </div>
    </div>
    <!-- 配置栏 -->
    <div class="option">
      <!-- 选择框 -->
      <div>
        <!-- 角色 -->
        <div style="display: inline-block; margin-right: 30px">
          <div
            style="display: inline-block; margin-left: 10px"
            @click="openRole"
          >
            {{ $t("label.role") }}：{{
              roleName == "" ? $t("label.role.no") : $t("label.role.select")
            }}
            <span style="margin-left: 15px; color: #006dcc; cursor: pointer"
              >[{{ $t("label.stdashbord.xsld.business.ajssx") }}]</span
            >
          </div>
        </div>
        <span>
          <!-- 记录类型 -->
          {{ $t("label.RecordType") }}
          ：</span
        >
        <el-dropdown>
          <input
            type="text"
            readonly
            :value="
              recordType
                ? recordType
                : recordTypeList[0] && recordTypeList[0].name
            "
            style="padding-left: 10px; cursor: pointer"
          />

          <!-- recordType == ''
                ? $t('label_partner_workspace_member_all')
                : recordType -->
          <div class="iconPosition el-icon-caret-bottom"></div>
          <el-dropdown-menu>
            <div class="recordType">
              <div
                @click="choseRecord(index)"
                v-for="(item, index) in recordTypeList"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 文本框 -->
      <div style="position: relative; margin-top: 10px">
        <!-- 所有人 -->
        <div style="display: inline-block">
          <div style="display: inline-block">
            <span style="margin-left: 10px">
              <!-- 所有人 -->
              {{ $t("label.owner") }}
              ：</span
            >
          </div>

          <div class="input_s" @click="openOwner">
            <!-- <el-dropdown trigger="click"> -->
            <input
              style="cursor: pointer"
              v-model="ownerName"
              type="text"
              ref="role2Input"
              readonly
            />
            <svg class="icon" aria-hidden="true">
              <use href="#icon-sousuo"></use>
            </svg>
          </div>
        </div>
        <span style="margin-left: 15px">
          <!-- 开始时间 -->
          {{ $t("label.starttime") }}
        </span>
        <div style="display: inline-block; position: relative">
          <el-date-picker
            v-model="startDate"
            type="date"
            :placeholder="$t('label_tabpage_selectdate')"
          >
          </el-date-picker>
        </div>
        <span style="margin-left: 10px">
          <!-- 结束时间 -->
          {{ $t("label.endtime") }}
        </span>
        <div style="display: inline-block; position: relative">
          <el-date-picker
            v-model="endDate"
            type="date"
            :placeholder="$t('label_tabpage_selectdate')"
          >
          </el-date-picker>
        </div>
        <button @click="getResult" class="searchButton">
          <!-- 查询 -->
          {{ $t("chatter.group.query") }}
        </button>
      </div>
    </div>
    <!-- 数据栏 -->
    <div class="data">
      <!-- 漏斗 -->
      <div style="width: 1052px; margin: 30px auto">
        <div
          class="hopperData"
          v-for="(item, index) in upgradeHopperArr"
          :key="index"
          :style="{
            width: 1052 - index * 15 + 'px',
            marginLeft: index * 15 + 'px',
          }"
        >
          <!-- :class="{ active: currentIndex == index?true:false }" -->
          <ul
            class="percent-phase"
            :class="{ active: currentIndexThree == index ? true : false }"
          >
            <div class="dountdown">
              <!-- <span>@</span>
              <span>54秒</span> -->
            </div>
            <li
              :class="{
                activeChild: currentIndexFirst == index ? true : false,
              }"
              @click="phaseFirstFun(item, index, 'first')"
              v-show="item.probability !== '100'"
            >
              <span>{{ $t("vue_label_salesadvance_lost") }}</span>
              <span>{{ getPercent(item.liushi_num, item.tuijin_num) }}</span>
              <span>{{ item.liushi_num }}</span>
            </li>
            <li
              :class="{ activeChild: currentIndexTwo == index ? true : false }"
              @click="phaseTwoFun(item, index, 'two')"
              v-show="item.probability !== '100'"
            >
              <span>{{ $t("vue_label_salesadvance_retention") }}</span>
              <span>{{ item.zhiliu_num }}</span>
            </li>
            <el-tooltip placement="top" v-show="item.probability !== '100'">
              <div slot="content" class="tooltip_s">
                <div>{{ item.codevalue }}</div>
                <div>
                  {{ $t("vue_label_salesadvance_opportunities") }}：{{
                    item.tuijin_num
                  }}
                </div>
                <div>
                  {{ $t("vue_label_salesadvance_losts") }}：{{
                    item.liushi_num
                  }}
                </div>
                <div>
                  {{ $t("vue_label_salesadvance_converserate") }}：
                  <span
                    v-if="upgradeHopperArr.length - 2 != index"
                    style="margin-left: -4px"
                  >
                    {{
                      upgradeHopperArr[index + 1]
                        ? getPercent(
                            upgradeHopperArr[index + 1].tuijin_num,
                            item.tuijin_num
                          )
                        : ""
                    }}</span
                  >
                  <span v-else style="margin-left: -4px">
                    {{
                      upgradeHopperArr[index + 1]
                        ? getPercent(
                            upgradeHopperArr[index + 1].zhiliu_num,
                            item.tuijin_num
                          )
                        : ""
                    }}
                  </span>
                </div>
                <div>
                  {{ $t("vue_label_salesadvance_lostrate") }}：{{
                    getPercent(item.liushi_num, item.tuijin_num)
                  }}
                </div>
                <div>
                  {{ $t("vue_label_salesadvance_retentions") }}：{{
                    item.zhiliu_num
                  }}
                </div>
              </div>
              <li
                :class="{
                  activeChild: currentIndexThree == index ? true : false,
                }"
                @click="phaseThreeFun(item, index)"
              >
                <span>{{ item.tuijin_num }}</span>
                <span>{{ item.codevalue }}</span>
              </li>
            </el-tooltip>
            <li
              :class="{
                activeChild: currentIndexThree == index ? true : false,
              }"
              @click="phaseThreeFun(item, index)"
              v-show="item.probability === '100'"
              style="padding: 0 20px; width: 100%"
            >
              <span
                v-if="upgradeHopperArr.length - 1 != index"
                style="float: right; color: #080707; font-size: 24px"
                >{{ item.tuijin_num }}</span
              >
              <span
                v-else
                style="float: right; color: #080707; font-size: 24px"
                >{{ item.zhiliu_num }}</span
              >
              <!-- 赢单 -->
              <span style="float: right; color: #080707; margin-right: 10px">{{
                $t("vue_label_salesadvance_win")
              }}</span>
            </li>
          </ul>
          <div class="grid-analysis" v-show="item.probability !== '100'">
            <span v-if="upgradeHopperArr.length - 2 != index">
              {{
                upgradeHopperArr[index + 1]
                  ? getPercent(
                      upgradeHopperArr[index + 1].tuijin_num,
                      item.tuijin_num
                    )
                  : ""
              }}</span
            >
            <span v-else>
              {{
                upgradeHopperArr[index + 1]
                  ? getPercent(
                      upgradeHopperArr[index + 1].zhiliu_num,
                      item.tuijin_num
                    )
                  : ""
              }}
            </span>
            <svg class="icon inline_block" aria-hidden="true">
              <use href="#icon-open"></use>
            </svg>
          </div>
          <div class="grid-block" v-show="item.probability === '100'">
            <!-- 赢单金额 -->
            <span
              >{{ $t("vue_label_salesadvance_winamount") }}：
              <em v-if="item.totaljine" style="font-style: normal"
                >{{
                  Number(item.totaljine)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                }}
              </em>
              <em v-else-if="item.totaljine >= 0" style="font-style: normal"
                >{{
                  Number(item.totaljine)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                }}
              </em>
              <em v-else style="font-style: normal">-</em>
            </span>
            <!-- 平均客单价 -->
            <span
              >{{ $t("vue_label_salesadvance_averageamount") }}：
              <em v-if="item.avgjine" style="font-style: normal"
                >{{
                  Number(item.avgjine)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                }}
              </em>
              <em v-else-if="item.avgjine >= 0" style="font-style: normal"
                >{{
                  Number(item.avgjine)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                }}
              </em>
              <em v-else style="font-style: normal">-</em>
            </span>
            <!-- <span>销售周期：39分钟</span> -->
            <!-- 赢单率 -->
            <span style="margin-right: 20px"
              >{{ $t("label.stdashbord.xsld.business.ydzhl") }}：
              <em v-if="item.yingdanlv" style="font-style: normal"
                >{{ Number(item.yingdanlv).toFixed(2) }}%
              </em>
              <em v-else style="font-style: normal">-%</em>
            </span>
          </div>
        </div>
      </div>
      <!-- 业务机会 -->
      <div class="opportanity" v-loading="loadData">
        <!-- title -->
        <div style="padding-left: 15px">
          {{ $t("lable.quote.syncing.opportunity") }}
        </div>
        <!-- 表格 -->
        <div class="tableData">
          <div
            class="tableTitle tableTitle2"
            style="
              display: flex;
              justify-content: space-between;
              padding: 10px 20px 10px 20px;
            "
          >
            <div>
              {{ $t("label.paging.totalofrecords", [listData.totalSize]) }}
            </div>
            <div>
              <!-- 关键字 -->
              {{ $t("label.keywords") }}
              ：<input type="text" @keyup.13="addInput" v-model="userInput" />
              <button class="btn" @click="addInput">
                <!-- 搜索 -->
                {{ $t("label.searchs") }}
              </button>
            </div>
          </div>
          <!-- 数据 -->
          <el-table
            :empty-text="$t('label.weixin.nomore')"
            ref="multipleTable"
            max-height="400"
            :data="listsData"
          >
            <el-table-column
              v-for="(item, index) in headList"
              :key="index"
              :label="item.fieldLabel"
              :prop="
                item.fieldType == 'R' || item.fieldType == 'Y'
                  ? item.apiname + 'ccname'
                  : item.apiname
              "
            >
              <template slot-scope="scope">
                <!-- name列 -->
                <a
                  v-if="item.apiname === 'name'"
                  class="allowClick"
                  @click="jumpToDetail(item, scope.row)"
                  :href="nameHrefUrl(item, scope.row)"
                  >{{
                    scope.row[
                      item.fieldType == "R" || item.fieldType == "Y"
                        ? item.apiname + "ccname"
                        : item.apiname
                    ]
                  }}</a
                >
                <!-- 所有人  可跳转 -->
                <a
                  v-else-if="
                    item.apiname === 'ownerid' &&
                    scope.row[`${item.apiname}`] &&
                    scope.row[`${item.apiname}`].slice(0, 3) === '005'
                  "
                  class="allowClick"
                  @click="jumpToDetail(item, scope.row)"
                  :href="nameHrefUrl(item, scope.row)"
                  >{{
                    scope.row[
                      item.fieldType == "R" || item.fieldType == "Y"
                        ? item.apiname + "ccname"
                        : item.apiname
                    ]
                  }}</a
                >
                <!-- 所有人  不可跳转 -->
                <span
                  v-else-if="
                    item.apiname === 'ownerid' &&
                    scope.row[`${item.apiname}`] &&
                    scope.row[`${item.apiname}`].slice(0, 3) !== '005'
                  "
                  >{{
                    scope.row[
                      item.fieldType == "R" || item.fieldType == "Y"
                        ? item.apiname + "ccname"
                        : item.apiname
                    ]
                  }}</span
                >
                <!-- 可跳转字段 -->
                <a
                  v-else-if="
                    item.fieldType == 'Y' && item.apiname !== 'ownerid'
                  "
                  class="allowClick"
                  @click="jumpToDetail(item, scope.row)"
                  :href="nameHrefUrl(item, scope.row)"
                  >{{
                    scope.row[
                      item.fieldType == "R" || item.fieldType == "Y"
                        ? item.apiname + "ccname"
                        : item.apiname
                    ]
                  }}</a
                >
                <!-- 不可跳转字段 -->
                <a v-else>{{
                  scope.row[
                    item.fieldType == "R" || item.fieldType == "Y"
                      ? item.apiname + "ccname"
                      : item.apiname
                  ]
                }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 搜索对象设置 -->
    <el-dialog
      :title="$t('label.stdashbord.xsld.business.ajssx')"
      :visible.sync="showOpenRole"
      width="500px"
    >
      <shuttle-box
        :role-data="roleData"
        :role-name="roleName"
        @cancel="changeSearch"
        @deliverLength="deliverLength"
      ></shuttle-box>
    </el-dialog>
    <!-- 所有人设置 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :fieldId="fieldId"
          :checked="checkEd"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import shuttleBox from "@/components/Tree/choseRole.vue";
import roleTreeMixin from "@/mixin/roleTree.js";
import {
  getRecordTypes,
  getListHeaders,
  jieduanListData,
  jieduanFunnelData,
} from "./api.js";

export default {
  mixins: [roleTreeMixin],
  components: {
    shuttleBox,
  },
  data() {
    return {
      hasResult: true,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "ffe4102000032890C8YC",
      relevantObjId: "user",
      relevantObjApi: "User",
      relevantPrefix: "005",
      checkEd: false,
      checked: true,
      showOpenRole: false,
      roleName: "",
      //保存角色的id
      selectedRole: [],
      selectedRoles: "",
      //所有人的id
      ownerid: "",
      // 保存记录类型名称
      recordType: "",
      //保存记录类型id名称
      recordTypeId: "",
      // all
      // 是否可选择角色
      canChoseRole: true,
      //是否显示记录类型框
      showOpenRecord: false,
      showSearchTable: false,
      startDate: `${new Date().getFullYear()}-1-1`,
      endDate: ``,
      // startShow: false,
      endShow: false,
      //保存记录类型
      recordTypeList: [],
      //保存表头字段
      headList: [],
      //保存业务汇总列表数据
      totalList: [],
      // 保存统计表格合计信息
      totalInfo: {},
      // 保存漏斗所需数据
      hopperData: [],
      // 保存下方数据
      listData: [],
      listsData: [],
      leng: 0,
      //请求数据的页数
      pno: 0,
      loadData: false,
      // 保存当前选中的阶段
      selectedProcess: "",
      userInput: "",
      showMore: false,
      ownerName: "",
      currency: "",
      newList: [],
      // loadOption: false,
      currentIndexFirst: -1,
      currentIndexTwo: -1,
      currentIndexThree: -1,
      upgradeHopperArr: [],
      upgradeType: "",
    };
  },
  created() {},
  mounted() {
    // this.loadOption = true;
    this.loadData = true;
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    this.getRecordTypeList();
    this.getHeaderList();
    this.endDate = `${year}-${month}-${day}`;
    this.getLists(false, true);

    this.$refs.multipleTable.bodyWrapper.addEventListener(
      "scroll",
      (res) => {
        let height = res.target;
        let clientHeight = height.clientHeight;
        let scrollTop = height.scrollTop;
        let scrollHeight = height.scrollHeight;
        if (scrollHeight - scrollTop - clientHeight <= 0) {
          if (this.showMore == false) {
            this.loadMore();
          }
        }
      },
      true
    );
  },
  methods: {
    //计算百分比
    getPercent(num, total) {
      /// <param name="num">当前数</param>
      /// <param name="total">总数</param>
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0
        ? "0%"
        : Math.round((num / total) * 10000) / 100.0 + "%";
    },
    jumpToDetail(item, scope) {
      let id = "";
      if (item.apiname == "name") {
        id = scope.id;
      } else {
        id = scope[item.apiname];
      }
      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
    },
    nameHrefUrl(item, scope) {
      let id = "";
      if (item.apiname == "name") {
        id = scope.id;
      } else {
        id = scope[item.apiname];
      }
      return id ? `#/commonObjects/detail/${id}/DETAIL` : "";
    },
    //获取角色树信息
    openRole() {
      this.getRoleLists();
      this.showOpenRole = true;
    },
    //  获取所有人
    openOwner() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
    //收起弹出框
    changeSearch() {
      this.showOpenRole = false;
    },
    deliverLength(result) {
      if (result.length > 0) {
        this.roleName = "hasRole";
        this.selectedRole = result;
        this.ownerName = "";
        this.ownerid = "";
      } else {
        this.roleName = "";
        this.selectedRole = [];
      }
      this.getResult();
    },
    //获取记录类型
    async getRecordTypeList() {
      let result = await getRecordTypes();
      this.recordTypeList = result.data.recordtypeList;
      if (this.recordTypeId === "") {
        this.recordTypeId = this.recordTypeList[0].id;
        this.recordType = this.recordTypeList[0].name;
      }
      let data = {
        startdate: this.startDate,
        enddate: this.endDate,
        recordtypeid: this.recordTypeId,
      };

      this.getQueryFunnelDate(data);
    },
    //获取表头字段
    async getHeaderList() {
      let result = await getListHeaders();
      this.headList = result.data;
      this.leng = this.headList.length;
      this.length = result.data.length;
    },
    //获取销售阶梯数据
    async getQueryFunnelDate(data) {
      let result = await jieduanFunnelData(data);
      if (!result.data) {
        //"系统异常，请联系管理员"
        this.$message.error(
          this.$i18n.t("vue_label_email_contact_adminstrator")
        );
      } else {
        this.currency = result.data.currency;
        this.upgradeHopperArr = result.data.jieduanoptions.filter(
          (obj) => obj.probability !== "0"
        );
        if (this.hopperData.length == 0) {
          this.hasResult = false;
        } else {
          this.hasResult = true;
        }
      }
    },
    choseRecord(index) {
      this.recordType = this.recordTypeList[index].name;
      this.recordTypeId = this.recordTypeList[index].id;
      this.getResult();
    },
    //拼接时间
    getDate(date) {
      if (!date) {
        return "";
      } else {
        var array = [];
        if (typeof date !== "string") {
          array.push(date.getFullYear());
          array.push(date.getMonth() + 1);
          array.push(date.getDate());
          return array.join("-");
        } else {
          return date;
        }
      }
    },
    getResult() {
      this.clearFun();
      this.startDate = this.getDate(this.startDate);
      this.endDate = this.getDate(this.endDate);
      let startDate = this.startDate.split("-");
      let endDate = this.endDate.split("-");
      let result = true;
      if (Number(startDate[0]) < Number(endDate[0])) {
        result = true;
      } else if (Number(startDate[0]) == Number(endDate[0])) {
        if (Number(startDate[1]) < Number(startDate[1])) {
          result = true;
        } else if (Number(startDate[1]) == Number(endDate[1])) {
          if (Number(startDate[2]) <= Number(endDate[2])) {
            result = true;
          } else if (Number(startDate[2]) > Number(endDate[2])) {
            result = false;
          }
        } else if (Number(startDate[1]) > Number(endDate[1])) {
          result = false;
        }
      } else if (Number(startDate[0]) > Number(endDate[0])) {
        result = false;
      }
      if (!result) {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.weixin.end.later.start"),
        });
        return;
      }
      if (this.selectedRole.length != 0) {
        this.selectedRoles = this.selectedRole.toString();
      } else {
        this.selectedRoles = "";
      }
      //  this.recordTypeId=this.recordTypeId;
      // 设置滚动条的高度为0
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      // this.loadOption = true;
      this.getLists(false, true);
      let object = {
        enddate: this.endDate,
        startdate: this.startDate,
        recordtypeid: this.recordTypeId
          ? this.recordTypeId
          : this.recordTypeList[0] && this.recordTypeList[0].id,
        searchKeyWord: this.userInput,
        roleids: this.selectedRoles,
        ownerid: this.ownerid,
      };
      this.getQueryFunnelDate(object);
    },
    //  获取业务机会列表数据
    async getLists(type, process) {
      if (process == true) {
        this.selectedProcess = "";
      }
      if (type == false) {
        this.pno = 1;
      }
      let obj = {
        enddate: this.endDate,
        startdate: this.startDate,
        jieduan: this.selectedProcess ? this.selectedProcess : "",
        recordtypeid: this.recordTypeId,
        page: this.pno,
        pageSize: "20",
        searchKeyWord: this.userInput,
        roleids: this.selectedRoles,
        ownerid: this.ownerid,
        type: this.upgradeType,
      };
      let result = await jieduanListData(obj);
      if (!result.data) {
        this.$message.error(
          this.$i18n.t("vue_label_email_contact_adminstrator")
        );
      } else {
        this.listData = result.data;
        var array = result.data.list;
        if (type == true) {
          this.listsData = this.listsData.concat(array);
        } else {
          this.listsData = [];
          this.listsData = array;
        }
        let resultList = [];
        this.listsData.map((item) => {
          let obj = {};
          this.headList.map((Sitem) => {
            let str = "";
            if (Sitem.fieldType == "Y") {
              str = Sitem.apiname + "ccname";
            } else {
              str = Sitem.apiname;
            }
            obj[str] = item[str];
          });
          for (var Ikey in item) {
            if (!obj[Ikey]) {
              obj[Ikey] = item[Ikey];
            }
          }
          resultList.push(obj);
        });
        this.listsData = resultList;
        if (result.data.list.length < 20) {
          this.showMore = true;
        } else {
          this.showMore = false;
        }
        this.loadData = false;
      }
    },
    loadMore() {
      this.pno++;
      this.loadData = true;
      this.getLists(true, false);
    },
    addInput() {
      this.loadData = true;
      this.pno = "1";
      // 设置滚动条的高度为0
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      this.getLists(false, false);
    },
    changeSelect(data) {
      if (data.length !== 0) {
        this.ownerid = data.data.id;
        this.ownerName = data.data.name;
        this.roleName = "";
      } else {
        this.ownerid = "";
        this.ownerName = "";
      }
      this.showSearchTable = false;
    },
    //点击表框1样式
    phaseFirstFun(item, i, val) {
      if (val === "first") {
        this.currentIndexFirst = i;
        this.currentIndexTwo = -1;
        this.currentIndexThree = -1;
        this.upgradeType = "0";
        this.selectedProcess = item.id;
        this.getLists(false, false);
      }
    },
    //点击表框2样式
    phaseTwoFun(item, i, val) {
      if (val === "two") {
        this.currentIndexTwo = i;
        this.currentIndexFirst = -1;
        this.currentIndexThree = -1;
        this.upgradeType = "1";
        this.selectedProcess = item.id;
        this.getLists(false, false);
      }
    },
    //点击表框3样式
    phaseThreeFun(item, i) {
      this.currentIndexFirst = i;
      this.currentIndexTwo = i;
      this.currentIndexThree = i;
      if (item.probability === "100") {
        this.upgradeType = "1";
      } else {
        this.upgradeType = "2";
      }
      this.selectedProcess = item.id;
      this.getLists(false, false);
    },
    //清空效果
    clearFun() {
      this.currentIndexTwo = -1;
      this.currentIndexFirst = -1;
      this.currentIndexThree = -1;
    },
  },
};
</script>

<style scoped>
.btn {
  width: 50px;
  background: #006dcc;
  border-radius: 3px;
  color: #ffffff;
  height: 22px;
  border: none;
  font-size: 12px;
  cursor: pointer;
}
.btn:focus {
  outline: none;
}
.canChose {
  color: #006dcc;
}
.noChose {
  /*  */
  color: #dddbda;
}
.canChoseInput {
  background: #ffffff;
}
.hopper {
  /* width: 100%; */
  /* height: 900px; */
  margin: 10px;
  border-radius: 4px;
  background-color: #ffffff;
}
.title {
  background: #fafaf9;
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #dddbda;
}
.title svg {
  width: 36px;
  height: 36px;
}
.textTitle {
  margin-left: 10px;
  margin-top: -3px;
  height: 36px;
}
.textTitle div {
  font-family: SourceHanSansCN-Medium;
  font-size: 16px;
  color: #080707;
}
.option {
  background: #fafaf9;
  padding: 10px;
  border-bottom: 1px solid #dddbda;
}
.option .input_s {
  position: relative;
  display: inline-block;
}
.option .input_s svg {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
input {
  /* width: 150px; */
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  /* text-align: center; */
}
.iconPosition {
  position: absolute;
  right: 5px;
  top: 5px;
}
.searchButton {
  width: 45px;
  height: 23px;
  background-color: #006dcc;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  margin-left: 10px;
  cursor: pointer;
}
.total {
  width: 60%;
  min-height: 450px;
  vertical-align: top;
}
input:focus {
  outline: none;
}
.recordTypeResult {
  position: absolute;
  left: 30px;
  top: 235px;
  width: 300px;
  height: 150px;
  border-radius: 4px;
  background: purple;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
  margin-left: 10px;
}
::v-deep .el-input__inner {
  height: 25px;
}
::v-deep .el-input__icon {
  line-height: 25px;
}
.recordType {
  /* width: 100px; */
  cursor: pointer;
}
.recordType div {
  /* margin: 15px 0px; */
  padding: 5px 15px;
}
.recordType div:hover {
  background-color: #ecf5ff;
  color: #7ac2fd;
}
.tableTitle {
  font-family: SourceHanSansCN-Medium;
  font-size: 12px;
  color: #333333;
  background: #f3f2f2; /*display:flex;height:40px;
        padding-left: 5px;
        padding-top: 10px;*/
  border-bottom: 1px solid #dddbda;
  /* line-height: 40px; */
  /* border: 1px solid #DDDBDA; */
}
.tableTitle2 {
  display: flex;
  height: 40px;
  padding-left: 5px;
  padding-top: 10px;
}
table {
  width: 100%;
  font-family: SourceHanSansCN-Medium;
  color: #333333;
}
table tr {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #dddbda;
  font-size: 12px;
}
td {
  min-width: 70px;
  max-width: 150px;
}
.footer {
  /* display: flex; */
  width: 100%;
  height: 40px;
  background: #f3f2f2;
  border-bottom: 1px solid #dddbda;
}
.footer div {
  display: inline-block;
  border-right: 1px solid #dddbda;
  line-height: 40px;
}
.opportanity {
  /* margin-top: 30px; */
  padding: 10px;
  font-family: SourceHanSansCN-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
}
.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tableData {
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  /* height: 400px; */
}
td {
  padding-left: 20px !important;
}
/* .totalTitle{
  display: table;
  font-size: 14px;
  padding-left: 20px;
  width:100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #dddbda;
} */
/* .chart_div{
  padding-left: -10px;
  position: absolute;
  left: 0px;
  top:30px;
} */
::v-deep .el-input__inner {
  cursor: pointer;
}
::v-deep .el-table {
  font-size: 12px;
}
::v-deep .el-table td,
.el-table th {
  padding: 8px 0;
  overflow-x: hidden;
  /* overflow: hidden; */
}
::v-deep .el-table .cell {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.allowClick {
  color: #006dcc;
  text-decoration: solid;
  cursor: pointer;
}
a {
  color: #000;
  cursor: auto;
}
::v-deep .allowClick.is-leaf {
  color: #3e3e3c;
  cursor: auto;
}
::v-deep .el-table thead {
  color: #3e3e3c;
}
.nowrap td {
  white-space: pre-wrap;
  word-wrap: break-word;
}
/* ::v-deep .el-table__empty-block{
  display: none;
} */
.percent-phase {
  position: relative;
  display: flex;
  height: 44px;
  line-height: 44px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  margin-bottom: 0;
}

.percent-phase li {
  cursor: pointer;
}
.percent-phase li:nth-child(2) {
  width: 219px;
}
.percent-phase li:nth-child(3) {
  width: 171px;
}
.percent-phase li:nth-child(4) {
  flex: 1;
  padding: 0 20px;
}
.percent-phase li:nth-child(2),
.percent-phase li:nth-child(3) {
  border-right: 1px solid #d6e1ee;
  padding: 0 20px;
}
.percent-phase li:nth-child(2) span:first-child,
.percent-phase li:nth-child(3) span:first-child {
  margin-right: 20px;
  color: #888888;
  font-size: 14px;
}
.percent-phase li:nth-child(2) span:nth-child(2) {
  color: #006dcc;
  overflow: hidden;
  max-width: 62px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.percent-phase:hover {
  border: 1px solid #006dcc;
}
.percent-phase li:nth-child(3):hover,
.percent-phase li:nth-child(2):hover {
  background: #dceeff;
}
.percent-phase li:nth-child(2) span:nth-child(3) {
  overflow: hidden;
  max-width: 64px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #006dcc;
  float: right;
  font-size: 20px;
}
.percent-phase li:nth-child(3) span:nth-child(2) {
  float: right;
  font-size: 20px;
}
.percent-phase li:nth-child(4) span {
  float: right;
  color: #080707;
}
.percent-phase li:nth-child(4) span:first-child {
  font-size: 20px;
}
.percent-phase li:nth-child(4) span:last-child {
  margin-right: 10px;
  font-size: 14px;
}
.grid-analysis,
.grid-block {
  height: 42px;
  line-height: 42px;
  width: 100%;
  text-align: right;
}
.grid-analysis span:nth-child(1) {
  color: #06844b;
  margin-right: 10px;
}
.grid-analysis span:nth-child(2) {
  color: #06844b;
}
.dountdown {
  position: absolute;
  left: -70px;
  color: #888888;
}
.dountdown span,
.grid-analysis span,
.grid-block {
  font-size: 12px;
}
.grid-analysis svg {
  margin-right: 20px;
}
.grid-block span {
  margin-left: 10px;
}
.active {
  border: 1px solid #006dcc;
}
.activeChild {
  background: #dceeff;
}
.tooltip_s div {
  margin-top: 3px;
}
</style>
