import { getRoles } from "./api.js";

export default {
  data() {
    return {
      roleData: [], // 筛选角色需要的数据
      roleLists: [], // 角色列表
    }
  },
  methods: {
    async getRoleLists() {
      this.roleData = []
      this.roleLists = []

      let result = await getRoles();
      this.roleLists.push(result.data);
      let str = JSON.stringify(this.roleLists);
      //将 rolename和name 替换成  label
      str = str.replace(/rolename/g, "label");
      str = str.replace(/name/g, "label");
      //将 childlist和childuserlist 替换成 children
      str = str.replace(/childrolelist/g, "children");
      str = str.replace(/childuserlist/g, "children");
      str = str.replace(/roleid/g, "id");
      this.roleData = JSON.parse(str);
    },
  }
}
